import { axios, generateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_GIFT_CARD_API,
  LIST_GIFT_CARD_API,
  FETCH_GIFT_CARD_API,
  DELETE_GIFT_CARD_API,
  UPDATE_GIFT_CARD_API, SYNC_SHOPIFY_GIFT_CARDS_API, QUERY_GIFT_CARDS_API
} from "api/giftCard/constants";


export const apiListGiftCards = ({ page, query }) => {
  const { url, method } = LIST_GIFT_CARD_API;

  return axios({
    method,
    url,
    params: {
      page,
      query,
    }
  });
};

export const apiCreateGiftCard = ({ giftCard }) => {
  const { url, method } = CREATE_GIFT_CARD_API;

  return axios({
    method,
    url,
    data: giftCardParams({ giftCard }),
  });
};

export const apiQueryGiftCard = ({ cardNumber }) => {
  const { url, method } = QUERY_GIFT_CARDS_API;

  return axios({
    method,
    url,
    data: {
      query: cardNumber,
    },
  });
};

export const apiFetchGiftCard = ({ giftCardId }) => {
  const { url, method } = FETCH_GIFT_CARD_API;

  return axios({
    method,
    url: generateRoute(url, giftCardId),
  });
};

export const apiUpdateGiftCard = ({ giftCard }) => {
  const { url, method } = UPDATE_GIFT_CARD_API;

  return axios({
    method,
    url: generateRoute(url, giftCard.id),
    data: giftCardParams({ giftCard }),
  });
};

export const apiDeleteGiftCard = ({ giftCard }) => {
  const { url, method } = DELETE_GIFT_CARD_API;

  return axios({
    method,
    url: generateRoute(url, giftCard.id),
  });
};

export const apiSyncShopifyGiftCards = () => {
  const { url, method } = SYNC_SHOPIFY_GIFT_CARDS_API;

  return axios({
    method,
    url,
  });
};

const giftCardParams = ({ giftCard }) => {
  const {
    name,
    cardNumber,
    // clinic_id: 'clinic__1',
    // staff_id: 'staff__1',
    surcharge,
    startDate,
    expiryDate,
    variety,
    initialBalance,
    minuteOrDollar,
    paymentMethod,
  } = giftCard;

  return {
    giftCard: {
      name,
      cardNumber,
      // clinic_id: 'clinic__1',
      // staff_id: 'staff__1',
      surcharge,
      startDate: startDate || new Date().toISOString(),
      expiryDate,
      variety,
      initialBalance,
      minuteOrDollar,
      paymentMethod,
      price: initialBalance,
    }
  }
};
