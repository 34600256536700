import {
  ASSIGN_PRACTITIONER_REPORT_ITEM_APPOINTMENT_TIME,
  ASSIGN_PRACTITIONER_REPORT_ITEM_CASH,
  ASSIGN_PRACTITIONER_REPORT_ITEM_DURATION,
  ASSIGN_PRACTITIONER_REPORT_ITEM_EFTPOS,
  ASSIGN_PRACTITIONER_REPORT_ITEM_HEALTH_FUND,
  ASSIGN_PRACTITIONER_REPORT_ITEM_HICAPS,
  ASSIGN_PRACTITIONER_REPORT_ITEM_INSURANCE_COMPANY,
  ASSIGN_PRACTITIONER_REPORT_ITEM_IS_REGULAR,
  ASSIGN_PRACTITIONER_REPORT_ITEM_IS_REMEDIAL,
  ASSIGN_PRACTITIONER_REPORT_ITEM_ITEM,
  ASSIGN_PRACTITIONER_REPORT_ITEM_MARGIN,
  ASSIGN_PRACTITIONER_REPORT_ITEM_OFF,
  ASSIGN_PRACTITIONER_REPORT_ITEM_OVERTIME,
  ASSIGN_PRACTITIONER_REPORT_ITEM_PHONE,
  ASSIGN_PRACTITIONER_REPORT_ITEM_POINTS_BALANCE,
  ASSIGN_PRACTITIONER_REPORT_ITEM_POINTS_BALANCE_QUERY,
  ASSIGN_PRACTITIONER_REPORT_ITEM_PROVIDER,
  ASSIGN_PRACTITIONER_REPORT_ITEM_PROVIDER_NUMBER,
  ASSIGN_PRACTITIONER_REPORT_ITEM_REDEEM_POINTS,
  ASSIGN_PRACTITIONER_REPORT_ITEM_TOTAL_SALE,
  ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER,
  ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_BALANCE,
  ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_BALANCE_QUERY,
  ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_NUMBER,
  ASSIGN_PRACTITIONER_REPORT_ITEM_SURCHARGE,
  NEW_PRACTITIONER_REPORT_ITEM,
  PULL_PRACTITIONER_REPORT_ITEM
} from "redux/model/practitionerReportItems/practitionerReportItems/constants";
import _ from 'lodash';
import {
  FETCH_ASSISTANT_REPORT_SUCCESS,
  INIT_ASSISTANT_REPORT
} from "redux/model/assistantReports/assistantReport/constants";
import {
  CREATE_PRACTITIONER_REPORT_SUCCESS, INIT_PRACTITIONER_REPORT, LOAD_PRACTITIONER_REPORT,
  UPDATE_PRACTITIONER_REPORT_SUCCESS
} from "redux/model/practitionerReports/practitionerReport/constants";

const INIT_STATE = {
  practitionerReportItems: {},
  savedPractitionerReportItems: {},
  loading: true,
  error: null,
};

const practitionerReportItemsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NEW_PRACTITIONER_REPORT_ITEM:
      const invoiceNumberRef = _.last(_.orderBy(_.concat(Object.values(state.practitionerReportItems), Object.values(state.savedPractitionerReportItems)), 'invoiceNumber'));

      const invoiceNumberCount = invoiceNumberRef ? parseInt(invoiceNumberRef.invoiceNumber.substr(invoiceNumberRef.invoiceNumber.length - 3)) : 0;

      return {
        ...state,
        loading: false,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.dummyId]: {
            id: action.payload.dummyId,
            invoiceNumber: _.padStart(invoiceNumberCount + 1, 3, '0'),
            appointmentTime: null,
            appointmentTimeRefId: action.payload.dummyId,
            duration: null,
            durationRefId: action.payload.dummyId,
            overtime: null,
            overtimeRefId: action.payload.dummyId,
            healthFund: null,
            healthFundRefId: action.payload.dummyId,
            totalSale: null,
            totalSaleRefId: action.payload.dummyId,
            hicaps: null,
            hicapsRefId: action.payload.dummyId,
            eftpos: null,
            eftposRefId: action.payload.dummyId,
            surcharge: null,
            surchargeRefId: action.payload.dummyId,
            cash: null,
            cashRefId: action.payload.dummyId,
            voucher: null,
            voucherRefId: action.payload.dummyId,
            voucherNumber: null,
            voucherNumberRefId: action.payload.dummyId,
            phone: null,
            phoneId: action.payload.dummyId,
            redeemPoints: null,
            redeemPointsId: action.payload.dummyId,
            item: null,
            itemRefId: action.payload.dummyId,
            margin: null,
            marginRefId: action.payload.dummyId,
            off: null,
            offRefId: action.payload.dummyId,
            provider: null,
            providerRefId: action.payload.dummyId,
            insuranceCompany: null,
            providerNumber: null,
            providerNumberRefId: action.payload.dummyId,
            isRegular: false,
            isRegularRefId: action.payload.dummyId,
            isRemedial: false,
            isRemedialRefId: action.payload.dummyId,
            consultationLinkRefId: action.payload.dummyId,
            surveyLinkRefId: action.payload.dummyId,
            actionId: action.payload.dummyId,
          }
        }
      }

    case PULL_PRACTITIONER_REPORT_ITEM:
      return {
        ...state,
        practitionerReportItems: _.omit(state.practitionerReportItems, action.payload.practitionerReportItem.id),
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_APPOINTMENT_TIME:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            appointmentTime: action.payload.appointmentTime,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_DURATION:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            duration: action.payload.duration,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_HEALTH_FUND:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            healthFund: action.payload.healthFund,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_TOTAL_SALE:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            totalSale: action.payload.totalSale,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_HICAPS:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            hicaps: action.payload.hicaps,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_EFTPOS:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            eftpos: action.payload.eftpos,
          },
        },
      };
      
    case ASSIGN_PRACTITIONER_REPORT_ITEM_SURCHARGE:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            surcharge: action.payload.surcharge,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_CASH:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            cash: action.payload.cash,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            voucher: action.payload.voucher,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_IS_REGULAR:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            isRegular: action.payload.isRegular,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_IS_REMEDIAL:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            isRemedial: action.payload.isRemedial,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_OVERTIME:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            overtime: action.payload.overtime,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_MARGIN:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            margin: action.payload.margin,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_OFF:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            off: action.payload.off,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_PROVIDER:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            provider: action.payload.provider,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_NUMBER:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            voucherNumber: action.payload.voucherNumber,
          },
        },
      };
    
    case ASSIGN_PRACTITIONER_REPORT_ITEM_PHONE:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            phone: action.payload.phone,
          }
        }
      }

    case ASSIGN_PRACTITIONER_REPORT_ITEM_REDEEM_POINTS:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            redeemPoints: action.payload.redeemPoints,
          }
        }
      }

    case ASSIGN_PRACTITIONER_REPORT_ITEM_ITEM:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            item: action.payload.item,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_INSURANCE_COMPANY:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            insuranceCompany: action.payload.insuranceCompany,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_PROVIDER_NUMBER:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            providerNumber: action.payload.providerNumber,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_BALANCE:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            voucherBalance: action.payload.voucherBalance,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_VOUCHER_BALANCE_QUERY:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            voucherBalanceQuery: action.payload.voucherBalanceQuery,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_POINTS_BALANCE:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            pointsBalance: action.payload.pointsBalance,
          },
        },
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_POINTS_BALANCE_QUERY:
      return {
        ...state,
        practitionerReportItems: {
          ...state.practitionerReportItems,
          [action.payload.practitionerReportItem.id]: {
            ...state.practitionerReportItems[action.payload.practitionerReportItem.id],
            pointsBalanceQuery: action.payload.pointsBalanceQuery,
          },
        },
      };

    case FETCH_ASSISTANT_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        savedPractitionerReportItems: _.mapKeys(action.payload.current.practitionerReportItems, 'id'),
      };

    case CREATE_PRACTITIONER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        savedPractitionerReportItems: {
          ...state.savedPractitionerReportItems,
          ..._.mapKeys(action.payload.current.practitionerReportItems, 'id')
        }
      }

    case UPDATE_PRACTITIONER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        savedPractitionerReportItems: {
          ...state.savedPractitionerReportItems,
          ..._.mapKeys(action.payload.current.practitionerReportItems, 'id')
        }
      }

    case INIT_ASSISTANT_REPORT:
      return INIT_STATE;

    case INIT_PRACTITIONER_REPORT:
      return {
        ...state,
        loading: true,
        error: null,
        practitionerReportItems: {},
      }

    case LOAD_PRACTITIONER_REPORT:
      return {
        ...state,
        practitionerReportItems: _.mapKeys(
          _.filter(Object.values(state.savedPractitionerReportItems), (reportItem) => {
            return _.includes(action.payload.practitionerReport.practitionerReportItemIds, reportItem.id);
          }),
          'id'
        ),
      }

    default:
      return { ...state };
  }
};

export default practitionerReportItemsReducer;
